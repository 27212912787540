import { Trans } from 'react-i18next'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { ArrowWrapper } from 'components/swap/styled'
import { CHAIN_IDS_TO_URL_PARAM } from 'constants/chains'
import { UNIVERSE_CHAIN_INFO } from 'uniswap/src/constants/chains'
import { UniverseChainId } from 'uniswap/src/types/chains'
import { useSwitchChain } from 'hooks/useSwitchChain'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import React from 'react'
import { useState, useCallback } from 'react'
import { ArrowDown } from 'react-feather'
import { useSearchParams } from 'react-router-dom'
import { Text } from 'rebass'
import { useAppSelector } from 'state/hooks'
import { useTransactionAdder, useTransaction } from 'state/transactions/hooks'
import { TransactionType, TransferTransactionInfo } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'
import { useAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { useAccount } from 'hooks/useAccount'

import ButtonDropdown from './ButtonDropDown'
import { FormWrapper } from './FormWrapper'

const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const transferWrapperStyle = {
  background: '#2c2f36',
  borderRadius: '10px',
  padding: '0px',
  marginBottom: '60px',
  color: '#c4cdd5',
  fontWeight: 485,
}

const transferTextStyle = {
  fontSize: '16px',
  letterSpacing: '1px',
  color: 'rgb(190 195 208)',
  fontWeight: 485,
  marginTop: 0,
}

const transferIconStyle = {
  fontSize: '30px',
  transform: 'rotate(90deg)',
  cursor: 'pointer',
}

type SetProcessState = (...args: any[]) => void
type SelectedNetwork = (...args: any[]) => void

export default function ConnectWalletForm({
  setProcessState,
  selectedNetwork,
  network,
}: {
  setProcessState: SetProcessState
  selectedNetwork: SelectedNetwork
  network: { network1: UniverseChainId; network2: UniverseChainId }
}) {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()
  const { provider } = useWeb3React()

  const { address, chainId } = useAccount()
  const [network1, setNetwork1] = useState<UniverseChainId>(network.network1)
  const [network2, setNetwork2] = useState<UniverseChainId>(network.network2)

  const accountDrawer = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    accountDrawer.open()
  }, [accountDrawer])

  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const swapNetwork = () => {
    const tempNetwork = network1
    setNetwork1(network2)
    setNetwork2(tempNetwork)
  }

  const handleSubmit = async () => {
    selectedNetwork({ network1, network2 })
    const txHash = searchParams.get('tx')
    console.log(txHash)
    if (!txHash) {
      searchParams.set('fromChain', CHAIN_IDS_TO_URL_PARAM[network1])
      searchParams.set('toChain', CHAIN_IDS_TO_URL_PARAM[network2])
      setSearchParams(searchParams)
      setProcessState('detail')
    } else {
      const transaction = await provider?.getTransaction(txHash)
      if (!transaction) {
        searchParams.set('fromChain', CHAIN_IDS_TO_URL_PARAM[network1])
        searchParams.set('toChain', CHAIN_IDS_TO_URL_PARAM[network2])
        setSearchParams(searchParams)
        setProcessState('detail')
        return
      }

      const info: TransferTransactionInfo = {
        type: TransactionType.TRANSFER,
        currency: '',
        amount: '',
      }

      try {
        addTransaction(transaction, info, deadline?.toNumber())
      } catch (e) {
        console.log(`Failed to add transaction ${txHash}: ${e.message}`)
      }

      setProcessState('progress')
    }
  }

  return (
    <FormWrapper>
      <div style={{ marginTop: '0px' }}>
        <p style={transferTextStyle}>From:</p>
        <div style={transferWrapperStyle}>
          <ButtonDropdown type="network" network={network1} swapNetwork={swapNetwork} />
        </div>
      </div>
      <ArrowWrapper clickable={true}>
        <ArrowContainer data-testid="swap-currency-button" onClick={swapNetwork} color={theme.neutral1}>
          <ArrowDown size="20" color={theme.neutral1} />
        </ArrowContainer>
      </ArrowWrapper>
      <div style={{ marginTop: '20px' }}>
        <p style={transferTextStyle}>To:</p>
        <div style={transferWrapperStyle}>
          <ButtonDropdown type="network" network={network2} swapNetwork={swapNetwork} />
        </div>
      </div>
      <div>
        {switchingChain ? (
          <ButtonPrimary type="button" $borderRadius="10px" disabled={true}>
            <Trans>Connecting to {UNIVERSE_CHAIN_INFO[switchingChain]?.label}</Trans>
          </ButtonPrimary>
        ) : chainId !== network1 ? (
          <ButtonPrimary
            type="button"
            $borderRadius="10px"
            onClick={async () => {
              try {
                await switchChain(network1)
              } catch (error) {
                console.log(error)
                if (didUserReject(error)) {
                  // Ignore error, which keeps the user on the previous chain.
                } else {
                  // TODO(WEB-3306): This UX could be improved to show an error state.
                  throw error
                }
              }
            }}
          >
            Connect to {UNIVERSE_CHAIN_INFO[network1]?.label}
          </ButtonPrimary>
        ) : !address ? (
          <ButtonLight type="button" onClick={handleWalletDropdownClick} fontWeight={535} $borderRadius="10px">
            <Trans>Connect Wallet</Trans>
          </ButtonLight>
        ) : (
          <ButtonLight type="button" $borderRadius="10px" onClick={handleSubmit}>
            <Text fontSize={20}>
              <Trans>Continue</Trans>
            </Text>
          </ButtonLight>
        )}
      </div>
    </FormWrapper>
  )
}
